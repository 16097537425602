(function ($) {
    $(window).on('load', function () {
        $('body').scrollspy({
            target: '#navbar-collapse',
            offset: 65
        });

        var scroll = {
            windowWidth: function () {
                return $(window).width();
            },
            offset: {
                mobile: 60,
                desktop: 60
            },
            changeUri: function () {
                $('a[data-scroll="section-another-page"]').each(function () {
                    var str = $(this).attr('href');
                    var hash = str.substr(str.search('#') + 1, str.length - 1);
                    var newURI = function () {
                        var parameters = {
                            section: hash
                        };
                        return str.replace('#' + hash, '') + '?' + $.param(parameters);
                    };
                    $(this).attr('href', newURI());
                });
            },
            animateToSection: function (hash) {
                var offset;
                if (this.windowWidth() < 768) {
                    offset = this.offset.mobile;
                } else if (this.windowWidth() >= 768) {
                    offset = this.offset.desktop;
                }
                $('html, body').animate({
                    scrollTop: $('#' + hash).offset().top - offset
                }, 500, function () {
                    $(this).clearQueue();
                });
            }
        }

        if (window.location.search) {
            var str = window.location.search.replace('?section=', '');
            setTimeout(function () {
                scroll.animateToSection(str);
            }, 0);
        }

        if ($('a[data-scroll="section-another-page"]').length > 0) {
            scroll.changeUri();
        }

        $('body').on('click', 'a[data-scroll="section-page"]', function (event) {
            event.preventDefault();
            var str = $(this).attr('href');
            var hash = str.substr(str.search('#') + 1, str.length - 1);
            scroll.animateToSection(hash);
        });
    });
}(jQuery));