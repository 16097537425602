(function($) {
    $(document).ready(function() {
        var windowWidth = $(window).width();
        var init;
        var config = {
            interval: 5000,
            lastIndexOfSlide: $('.slider-inner > .item:last').index(),
            activeSlide: $('.slider-inner > .active').index() + 1
        };

        function checkResolution() {
            stopSlide();
            windowWidth = $(window).width();
            if (windowWidth >= 768) {
                init = setInterval(startSlide, config.interval);
            } else {
                return false;
            }
        }

        function changeSlide(idx) {
            $('.slider-inner > .item, .slider-indicators > li').removeClass('active');
            $('.slider-inner > .item:eq(' + idx + '), .slider-indicators > li:eq(' + idx + ')').addClass('active');
        }

        function startSlide() {
            if (config.activeSlide < config.lastIndexOfSlide) {
                changeSlide(config.activeSlide);
                config.activeSlide++;
            } else {
                changeSlide(config.activeSlide);
                config.activeSlide = 0;
            }
        }

        function stopSlide() {
            clearInterval(init);
        }

        checkResolution();

        $('.slider-indicators > li').on('click', function() {
            var index = $(this).index();
            changeSlide(index);
            config.activeSlide = index;
        });

        $('.slider-inner').hover(function() {
            stopSlide();
        }, function() {
            checkResolution();
        });

        $(window).on('resize', function() {
            checkResolution();
        });

    });
}(jQuery));