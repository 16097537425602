angular.module('articleContentApp', [])
    .controller('ArticleContentCtrl', ['$scope', '$location', '$anchorScroll', function ($scope, $location, $anchorScroll) {
        $scope.template = [{
            url: 'tpl/what-is-a-breathalyzer-calibration.tpl.html'
        }, {
            url: 'tpl/where-to-calibrate-breathalyzer.tpl.html'
        }, {
            url: 'tpl/why-to-calibrate-a-breathalyzer.tpl.html'
        }, {
            url: 'tpl/privacy.tpl.html'
        }];

        $anchorScroll.yOffset = 0;
    }]);