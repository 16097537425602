(function($) {
    $(document).ready(function() {

        var scrollPos = $(window).scrollTop();
        var changeHeaderOn = 50;

        function animatedHeader(pos) {
            if (pos > changeHeaderOn) {
                $('header > nav').addClass('navbar-shrink');
            } else {
                $('header > nav').removeClass('navbar-shrink');
            }
        }

        animatedHeader(scrollPos);

        $(window).on('scroll', function() {
            animatedHeader($(window).scrollTop());
        });
    });
}(jQuery));