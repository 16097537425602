(function ($) {
    $(document).ready(function () {

        // Konfiguracja
        var config = {
            windowWidth: $(window).width(),
            mobileWidth: 767,
            minProducts: 3,
            interval: 5000,
            animationSpeed: 500
        };

        // Zmienne globalne
        var products;
        var productWidth;

        // Funkcja inicjująca slider
        function init() {
            clearAttributes();

            // Dezaktywacja kontrolek jeśli wymagana
            products = $('.slider-products-inner > div:last-child').index() + 1;
            if (products <= config.minProducts) {
                $('#slider-products').removeClass('active');
            } else {
                $('#slider-products').addClass('active');
            }

            // Automatyczna szerokość dla .slider-products-inner
            productWidth = $('.slider-products-inner > div:eq(0)').innerWidth();
            $('.slider-products-inner > div').css('width', productWidth);
            $('.slider-products-inner').css('width', productWidth * products);

            // Automatyczna wysokość dla bloku cechy produktu .features
            var featuresHeight = 0;
            $('.best-products .features').each(function () {
                if ($(this).height() > featuresHeight) {
                    featuresHeight = $(this).height();
                }
            });
            $('.best-products .features').css('height', featuresHeight);
        }

        // Czyszczenie atrybutów
        function clearAttributes() {
            $('.slider-products-inner, .slider-products-inner > div, .best-products .features').removeAttr('style');
            $('.slider-products').removeClass('active');
        }

        // Zmiana produktów
        function changeProduct(el) {
            if (el === '#next') {
                $('.slider-products-inner').animate({
                    'left': '-=' + productWidth
                }, config.animationSpeed, function () {
                    $(this).clearQueue();
                    var $firstElement = $(this).children('div:first');
                    $(this).append($firstElement.clone());
                    $firstElement.remove();
                    $(this).css('left', 0);
                });
            } else if (el === '#prev') {
                $('.slider-products-inner').animate({
                    'left': '-=' + productWidth
                }, 0, function () {
                    var $lastElement = $(this).children('div:last');
                    $(this).prepend($lastElement.clone());
                    $lastElement.remove();
                    $(this).animate({
                        'left': '+=' + productWidth
                    }, config.animationSpeed, function () {
                        $(this).clearQueue();
                    });
                });
            }
        }

        // Auto zmiana produktów (interwał)
        var startInterval;

        function startChangeProduct() {
            startInterval = setInterval(function () {
                changeProduct('#next');
            }, config.interval);
        }

        function stopChangeProduct() {
            clearInterval(startInterval);
        }

        // Aktywacja slidera w zależności od szerokości okna przeglądarki
        if (config.windowWidth > config.mobileWidth) {
            init();
            startChangeProduct();
        } else {
            clearAttributes();
            stopChangeProduct();
        }

        // Aktywacja slidera podczas zmiany szerokości okna przeglądarki
        $(window).on('resize', function () {
            config.windowWidth = $(window).width();
            if (config.windowWidth > config.mobileWidth) {
                init();
                stopChangeProduct();
                startChangeProduct();
            } else {
                clearAttributes();
                stopChangeProduct();
            }
        });

        // Kontrolery
        $('.slider-products-control').on('click', function (event) {
            event.preventDefault();
            changeProduct(event.target.hash);
        });

        // Dezaktywacja/aktywacja auto zmiany produktów po najechaniu myszką
        $('.slider-products').hover(function () {
            stopChangeProduct();
        }, function () {
            if (config.windowWidth > config.mobileWidth) {
                startChangeProduct();
            }
        });
    });
}(jQuery));